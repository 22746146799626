//* Main Functions
    // Global Variable
    var attendee_scanner;

    //* --------------
    //* Login Function
    //* --------------
    function login_store()
    {
        $('form[id="loginForm"]').each(function(){
            $(this).validate({
                ignore: ".ignore",
                rules: {
                    email: {
                        required: true,
                        email: true,
                        maxlength: 150
                    },
                    password: 'required'
                },
                errorPlacement: function(error, element) {
                    var elementName = element.attr("base_error");
                    error.appendTo("#"+elementName);
                },
                submitHandler: function(form) {
                    $.ajax({
                        url: form.action,
                        type: form.method,
                        data: $(form).serialize(),
                        cache: false,
                        processData: false,
                        beforeSend: function() {
                            $('#loginForm .btn-submit').html('Please wait...');
                            $('#loginForm .btn-submit').prop('disabled', true);
                        },
                        success: function(data) {
                            if(data.status == 'success'){
                                SnackBar({
                                    fixed: true,
                                    position: "tr",
                                    status: "success",
                                    message: data.message,
                                    timeout: 1700
                                });

                                setTimeout(function() {
                                    window.location.href = data.next;
                                }, 1700);
                            }else{
                                $('#loginForm .btn-submit').html('Sign In');
                                $('#loginForm .btn-submit').prop('disabled', false);
                                $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                            }
                        },
                        error: function (xhr, ajaxOptions, thrownError) {
                            $('#loginForm .btn-submit').html('Sign In');
                            $('#loginForm .btn-submit').prop('disabled', false);
                            $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                        }
                    });
                    return false;
                },
            });
        });
    }

    //* --------------
    //* Attendance Function
    //* --------------
    function attendance_init()
    {
        // Session form on select session product
        $(document).on('change', '#sessionForm select[name="select_product"]', function(){
            var this_form = this.form;

            $.ajax({
                url: this_form.action,
                type: this_form.method,
                data: $(this_form).serialize(),
                cache: false,
                processData: false,
                beforeSend: function() {
                    
                },
                success: function(data) {
                    if(data.status == 'success'){
                        SnackBar({
                            fixed: true,
                            position: "tr",
                            status: "success",
                            message: data.message,
                            timeout: 1700
                        });

                        setTimeout(function() {
                            window.location.href = data.next;
                        }, 1700);
                    }else{
                        $('.alert-message-box').html('<div class="alert alert-danger">'+data.message+'</div>');
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $('.alert-message-box').html('<div class="alert alert-danger">'+xhr.responseJSON.message+'</div>');
                }
            });
            return false;
        });
        // 
        $('#sessionForm select[name="select_product"]').select2({
            minimumInputLength: 2,
            ajax: {
                url: "/attendance/session/search",
                dataType: "json",
                type: "GET",
                data: function (term) {
                    return {
                        term: term
                    };
                },
                processResults: function (data) {
                    var res = data.map(function (item) {
                        return {id: item.id, text: item.name+' - ('+item.type+')'};
                    });
                    return {
                        results: res
                    };
                }
            }
        });

        // Scan function
        attendee_scanner = new Html5QrcodeScanner('reader', { 
            // Scanner will be initialized in DOM inside element with id of 'reader'
            qrbox: {
                width: 250,
                height: 250,
            },  // Sets dimensions of scanning box (set relative to reader element width)
            fps: 10, // Frames per second to attempt a scan
        });
        attendee_scanner.render(scan_success, scan_error);
    }
        // Starts scanner
        function scan_success(result) {
            attendee_scanner.clear();
            // Clears scanning instance
            document.getElementById('reader').remove();
            // Removes reader element from DOM since no longer needed

            // 
            $.ajax({
                url: '/attendance/scan',
                type: 'POST',
                data: {
                    code: result
                },
                beforeSend: function() {
                    $('.splash-notify').css({"display": "flex"});
                    $('.splash-notify .placement').html('<div class="inner">\
                        <div class="icon-status warning">\
                            <i class="bx bx-info-circle"></i>\
                        </div>\
                        <h5 class="status-msg mt-3">\
                            Please Wait...\
                        </h5>\
                    </div>');
                },
                success: function(data) {
                    if(data.status == 'success'){ 
                        $('.splash-notify').css({"display": "flex"});
                        $('.splash-notify .placement').html('<div class="inner">\
                            <div class="icon-status success">\
                                <i class="bx bx-check-circle"></i>\
                            </div>\
                            <h5 class="status-msg mt-3">\
                                Check In Successfully\
                            </h5>\
                            <p class="glob-description text-white">'+data.message+'</p>\
                            <div class="btn-box justify-content-center">\
                                <a href="" class="btn-submit secondary">Finish</a>\
                            </div>\
                        </div>');
                    }else{
                        $('.splash-notify').css({"display": "flex"});
                        $('.splash-notify .placement').html('<div class="inner">\
                            <div class="icon-status error">\
                                <i class="bx bx-x-circle"></i>\
                            </div>\
                            <h5 class="status-msg mt-3">\
                                Oops there is an error...\
                            </h5>\
                            <p class="glob-description text-white">'+data.message+'</p>\
                            <div class="btn-box justify-content-center">\
                                <a href="" class="btn-submit secondary">Finish</a>\
                            </div>\
                        </div>');
                    }
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    $('.splash-notify').css({"display": "flex"});
                    $('.splash-notify .placement').html('<div class="inner">\
                        <div class="icon-status error">\
                            <i class="bx bx-x-circle"></i>\
                        </div>\
                        <h5 class="status-msg mt-3">\
                            Oops there is an error...\
                        </h5>\
                        <p class="glob-description text-white">'+xhr.responseJSON.message+'</p>\
                        <div class="btn-box justify-content-center">\
                            <a href="" class="btn-submit secondary">Finish</a>\
                        </div>\
                    </div>');
                }
            });
        }
        function scan_error(err) {
            console.error(err);
            // Prints any errors to the console
        }